<template>
  <title>SIM INVENTORY ~ TRACEBILTY DATA</title>
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <h1>
      Tracebility Data
      <br />
      <h4>The following is a list of Tracebility Data</h4>
      <!-- <small>Control panel</small> -->
    </h1>
    <ol class="breadcrumb">
      <li>
        <a href="#"><i class="fa fa-dashboard"></i> Main Navigation</a>
      </li>
      <li class="active">Tracebility</li>
    </ol>
  </section>

  <section class="content">
    <div v-if="loading" class="load">
      <img width="130" src="@/assets/loader.gif" alt="" />
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header">
            <div class="box-header with-border">
              <i class="fa fa-list"></i>
              <h3 class="box-title">List Buyer Order Data</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  Show &nbsp; :
                  <select
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by number order "
                    class="form-control"
                    v-model="namesearch"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    @click="searchdata()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- /.box-header -->
          <div v-if="isExist2" class="box-body">
            <div class="table-responsive">
              <table id="mydata" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <!-- <th>Idx</th> -->
                    <th>Product Number</th>
                    <th>Buyer Code</th>
                    <th>Order Number</th>
                    <th>Order Date</th>
                    <th>Qty</th>
                    <th>Notes</th>
                    <th>Start Date Production</th>
                    <th>End Date Production</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(classdataorderbuyer, index) in list_paged"
                    :key="classdataorderbuyer.id"
                  >
                    <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                    <!-- <td>{{ classdataorderbuyer.id }}</td> -->
                    <td>{{ classdataorderbuyer.nomor_produk }}</td>
                    <td>{{ classdataorderbuyer.kode_buyer }}</td>
                    <td>
                      {{ classdataorderbuyer.order_number }}
                    </td>

                    <td>{{ classdataorderbuyer.tanggal_order }}</td>
                    <td>{{ classdataorderbuyer.qty }}</td>
                    <td>{{ classdataorderbuyer.notes }}</td>
                    <td>{{ classdataorderbuyer.tanggal_produksi_start }}</td>
                    <td>{{ classdataorderbuyer.tanggal_produksi_end }}</td>
                    <td>
                      <button
                        title="View Data"
                        @click="caritrace(classdataorderbuyer.order_number)"
                        class="btn btn-success"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                      <!-- <router-link :to="'/editbuyer-order/' + classdataorderbuyer.id">{{
                        classdataorderbuyer.order_number
                      }}</router-link> -->
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-pagination
                v-model="page"
                :pages="count"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="handlePageChange"
              />
            </div>
          </div>
          <div v-else class="box-body">
            <div class="table-responsive">
              <table id="mydata" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>No.</th>
                    <!-- <th>Idx</th> -->
                    <th>Product Number</th>
                    <th>Buyer Code</th>
                    <th>Order Number</th>
                    <th>Order Date</th>
                    <th>Qty</th>
                    <th>Notes</th>
                    <th>Start Date Production</th>
                    <th>End Date Production</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="10">
                      <center>
                        <b style="color: red"> ... Data Not Found ... </b>
                      </center>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-md-4">
        <div class="box box-primary">
          <div class="box-header with-border">
            <i class="fa fa-list"></i>
            <h3 class="box-title">Tracebility Data</h3>
          </div>
          <form role="form">
            <div class="box-body">
              <div class="form-group">
                <label for="exampleInputEmail1">Order Number</label>
                <input
                  type="text"
                  v-model="ordernumb"
                  autocomplete="off"
                  class="form-control"
                  placeholder="Please enter order number"
                />
              </div>
            </div>
            <br />
            <div class="box-footer">
              <button
                @click="caritrace()"
                type="button"
                class="btn btn-primary"
              >
                <i class="fa fa-search"></i> Search
              </button>
            </div>
          </form>
        </div>
      </div> -->

      <!-- <div v-if="isSearch">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-list"></i>
              <h3 class="box-title">Tracebility Data</h3>
            </div>
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Product Number</label>
                  <input
                    type="text"
                    readonly
                    v-model="produknumb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Product Number"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Number</label>
                  <input
                    type="text"
                    readonly
                    v-model="ordernumb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Order Number"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> -->
    </div>
    <div v-if="isSearch">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-6">
                    <label>Product Number</label>
                    <div class="input-group">
                      <span class="input-group-addon"
                        ><i class="fa fa-list"></i
                      ></span>
                      <input
                        type="text"
                        readonly
                        v-model="produknumb"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label>Order Number</label>
                    <div class="input-group">
                      <span class="input-group-addon"
                        ><i class="fa fa-edit"></i
                      ></span>
                      <input
                        type="text"
                        readonly
                        v-model="ordernumb"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <!--<div class="col-md-2">
                  Date &nbsp; :
                </div>
                <div class="col-md-3">
                  <input
                    type="date"
                    v-model="tglret"
                    class="form-control"
                    name=""
                    id=""
                  />
                </div>
                <div class="col-md-2">
                  Retur Number &nbsp; :
                </div>
                <div class="col-md-3">
                  <input
                    type="text"
                    v-model="nomorkewh"
                    class="form-control"
                    name=""
                    id=""
                    placeholder="Retur Number"
                  />
                </div> -->
                </div>
              </div>
            </div>

            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Tracebility Data</h3>
              </div>
            </div>

            <!-- /.box-header -->
            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Process Code</th>
                      <th>Process Date</th>
                      <th>Process Results</th>
                      <th>Batch Code</th>
                      <th>Order Number</th>
                      <th>Product Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(classdata, index) in listdata"
                      :key="classdata.nomor_produk"
                    >
                      <tr
                        data-toggle="collapse"
                        class="accordion-toggle"
                        :data-target="'#demo' + index"
                      >
                        <td>
                          <button class="btn btn-sm btn-success">
                            <i class="fa fa-plus"></i>
                          </button>
                        </td>
                        <td>{{ classdata.kode_proses }}</td>
                        <td>{{ classdata.tanggal_proses }}</td>
                        <td>{{ classdata.hasil_proses }}</td>
                        <td>{{ classdata.nomor_produksi }}</td>
                        <td>{{ classdata.nomor_order }}</td>
                        <td>{{ classdata.nomor_produk }}</td>
                      </tr>
                      <tr>
                        <td colspan="7">
                          <div
                            class="accordian-body collapse"
                            :id="'demo' + index"
                          >
                            <b>Material</b>
                            <table class="table table-bordered table-striped">
                              <thead>
                                <th>Item Code</th>
                                <th>Outgoing Number</th>
                                <th>Item Name</th>
                                <th>Qty</th>
                                <th>Supplier Name</th>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="lsdata in classdata.material"
                                  :key="lsdata.kode_proses"
                                >
                                  <td>{{ lsdata.kode_barang }}</td>
                                  <td>{{ lsdata.outgoing_number }}</td>
                                  <td>{{ lsdata.nama_barang }}</td>
                                  <td>
                                    {{ lsdata.qty }} &nbsp; {{ lsdata.unit }}
                                  </td>
                                  <td>{{ lsdata.nama_supplier }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <b>Part</b>
                            <table class="table table-bordered table-striped">
                              <thead>
                                <th>Process Code</th>
                                <th>Item Code</th>
                                <th>Outgoing Number</th>
                                <th>Item Name</th>
                                <th>Qty</th>
                                <th>Supplier Name</th>
                                <th>Batch Code</th>
                                <th>Order Number</th>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="lsdata in classdata.material"
                                  :key="lsdata.kode_proses"
                                >
                                  <td>{{ classdata.kode_proses }}<br /></td>
                                  <td>{{ lsdata.kode_barang }}</td>
                                  <td>{{ lsdata.outgoing_number }}</td>
                                  <td>{{ lsdata.nama_barang }}</td>
                                  <td>
                                    {{ lsdata.qty }} &nbsp; {{ lsdata.unit }}
                                  </td>
                                  <td>{{ lsdata.nama_supplier }}</td>
                                  <td>{{ lsdata.nomor_produksi }}</td>
                                  <td>{{ lsdata.nomor_order }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Process Code</th>
                      <th>Process Date</th>
                      <th>Process Results</th>
                      <th>Batch Code</th>
                      <th>Order Number</th>
                      <th>Product Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="6" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header">
              <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Tracebility Data (Material)</h3>
              </div>
            </div>

            <div v-if="isExist" class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Process Code</th>
                      <th>Item Code</th>
                      <th>Outgoing Number</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <th>Supplier Name</th>
                      <th>Batch Code</th>
                      <th>Order Number</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="classdata in listdata"
                    :key="classdata.nomor_produk"
                  >
                    <tr
                      v-for="(lsdata, index) in classdata.material"
                      :key="index"
                    >
                      <td>{{ classdata.kode_proses }}<br/>
                      </td>
                      <td>{{ lsdata.kode_barang }}</td>
                      <td>{{ lsdata.outgoing_number }}</td>
                      <td>{{ lsdata.nama_barang }}</td>
                      <td>{{ lsdata.qty }} &nbsp; {{ lsdata.unit }}</td>
                      <td>{{ lsdata.nama_supplier }}</td>
                      <td>{{ lsdata.nomor_produksi }}</td>
                      <td>{{ lsdata.nomor_order }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Process Code</th>
                      <th>Item Code</th>
                      <th>Outgoing Number</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                      <th>Supplier Name</th>
                      <th>Batch Code</th>
                      <th>Order Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="8" class="text-center">
                          <b style="color: red"> ... Data Not Found ... </b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</template>
<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import swal from "sweetalert";
import axios from "axios";

export default {
  name: "tracebility",
  components: {
    VPagination,
  },
  data() {
    return {
      ordernumb: "",
      loading: false,
      isSearch: false,
      isExist: true,
      isExist2: true,
      produknumb: "",
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
      classdataorderbuyer: {
        order_number: "",
      },
    };
  },
  created() {
    this.fetchDataOrderBuyer();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchDataOrderBuyer();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchDataOrderBuyer();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    searchdata() {
      this.loading = true;
      // const params = this.getRequestParams(this.page);
      const paramsst = this.getRequestParams(this.pagestat);
      const tokenlogin = sessionStorage.getItem("token");
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/getalldatabuyer_order?length="+this.pageSize+"&cari="+this.namesearch+"&page="+this.page
      const urlApiGETdetail =
        this.$apiurl +
        "buyer_order/getalldatabuyer_order?length=" +
        this.pageSize +
        "&cari=" +
        this.namesearch +
        "&page=" +
        this.page;
      axios
        .get(urlApiGETdetail, {
          paramsst,
          headers: { Authorization: tokenlogin },
        })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist2 = false;
          } else {
            this.isExist2 = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async fetchDataOrderBuyer() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/getalldatabuyer_order?length="+this.pageSize+"&page="+this.page
      const urlApiGETdetail =
        this.$apiurl +
        "buyer_order/getalldatabuyer_order?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist2 = false;
          } else {
            this.isExist2 = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    caritrace(classdataorderbuyer) {
      this.loading = true;
      this.isSearch = true;
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "tracebility/cariproduk?nomor_order=" +
        classdataorderbuyer;
      console.log(urlAPIget);
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            this.isExist = false;
            this.produknumb = "";
            this.ordernumb = "";
          } else {
            this.isExist = true;
            this.listdata = resp.data.data[0].part;
            this.produknumb = resp.data.data[0].nomor_produk;
            this.ordernumb = resp.data.data[0].nomor_order;
            console.log(resp.data.data);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>

